import React from 'react'
import Results from '../Results'

const HostawayResults = () => {
  return (
    <Results
      results={[
        { name: 'cycle time', value: 50, dir: 'down' },
        { name: 'review rate', value: 76, dir: 'up' },
        { name: 'technical debt', value: 79, dir: 'down' },
      ]}
    />
  )
}
export default HostawayResults
