import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const HostawayHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="Hostaway"
      subheading="Adam Kupcewicz, Director of Engineering at Hostaway, shares how Swarmia helped Hostaway understand their engineering organization better and ship faster."
      heading="How Hostaway started shipping four times faster while doubling their engineering headcount"
      featured={featured}
    />
  )
}

export default HostawayHeader

const query = graphql`
  {
    hero: file(
      relativePath: { eq: "images/story/hostaway/hostaway-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
