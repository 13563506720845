import React from 'react'
import adam from '../../../assets/images/story/hostaway/adam.png'
import image1 from '../../../assets/images/story/hostaway/code-overview-hostaway.png'
import image2 from '../../../assets/images/story/hostaway/review-rate-hostaway.png'
import image3 from '../../../assets/images/story/hostaway/working-agreements-hostaway.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'

const HostawayBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            Hostaway is a rental management software that saves property
            managers time by automating tedious manual tasks.
          </p>
        ),
        href: 'https://hostaway.com',
        location: <p>Headquartered in Finland, offices around the world</p>,
        headcount: <p>210+ employees, 40+ engineers</p>,
        customers: <p>2023</p>,
      }}
      main={
        <>
          <p>
            Adam Kupcewicz, Director of Engineering at Hostaway, leads the
            software organization responsible for supporting thousands of
            vacation rental property managers. Today, the globally distributed
            organization consists of more than 40 engineers.
          </p>
          <p>
            Before Swarmia, Adam only tracked a few engineering metrics. As
            their team grew, it became increasingly hard to understand what
            tasks developers were working on and whether there were productivity
            bottlenecks on a personal, team, or organizational level. This led
            him to look for a software engineering intelligence platform.
          </p>
          <InlineQuote
            quote="Swarmia allows us to focus on what’s important. We don’t want to be flooded with thousands of metrics and spend our resources on data analytics at this stage."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <p>
            After evaluating Swarmia and LinearB, Hostaway decided to onboard
            all their engineers to Swarmia, because of its ability to direct
            engineers’ attention to the metrics that matter.
          </p>
          <InlineQuote
            quote="We chose to work with Swarmia because it helps us concentrate on what's important at every level. We were able to identify the right metrics on the spot. It has worked very well for us."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <h2>
            Translating engineering work into understandable metrics and
            implementing healthy processes
          </h2>

          <p>
            The insights overview is the main view Adam uses to get the pulse of
            the organization. After implementing Swarmia, Adam could immediately
            see that the time to first review, review rate, and cycle time had
            all been trending in the wrong direction.
          </p>
          <ProductScreenshot src={image1} />
          <InlineQuote
            quote="With visibility into the right metrics in Swarmia, we were able to set the right ways of working, which significantly improved our lead time."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <h3>Measuring key engineering metrics</h3>
          <p>
            Adam then started digging deeper into the metrics, talking with the
            team, and implementing new processes.
          </p>
          <InlineQuote
            quote="Time to first review and low review rate were the first bottlenecks we spotted with Swarmia. Back then, only a few developers would review PRs, and everything got stuck. Finding and solving this was a major, major upgrade for us."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <ProductScreenshot src={image2} />
          <h3>Reducing time to deploy by 75% with working agreements</h3>
          <p>
            At Swarmia, each team can set their own working agreements that
            support teamwork and productivity.
          </p>
          <InlineQuote
            quote="After reviewing the data from Swarmia and talking with the team, we understood that instead of relying on solo contributors, we needed to start relying on teams. We then set the right working agreements, and our time to deploy reduced from four days to less than 24 hours."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <p>
            Hostaway’s team agreed to avoid pushing directly to the default
            branch, review pull requests in less than 3 days, and link PRs to
            issues, which boosted cycle times across the organization.
          </p>
          <ProductScreenshot src={image3} />
          <InlineQuote
            quote="By looking at the cycle time and comparing it to what our team has in their calendar, it was easy to understand what things have an influence on our team’s productivity."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <h3>Gaining clarity about engineering investments</h3>
          <p>
            The investment balance view in Swarmia helps Adam quickly see how
            the engineering organization splits time between building new tools,
            improving existing features, keeping the lights on (KTLO),
            productivity, and other work.
          </p>
          <InlineQuote
            quote="I use the investment balance view on a daily basis. With the breakdowns, I’m able to see all the necessary information at glance."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <p>
            The view also shows Adam the split between planned and unplanned
            work, helping him see how many interruptions teams have.
          </p>
          <InlineQuote
            quote="After implementing Swarmia, the time spent on building new features has steadily gone up, while technical debt has decreased significantly."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <p>
            It also helps Adam understand the team’s workload, leading to better
            structuring and planning of work, and well-justified recruitment
            decisions.
          </p>
          <InlineQuote
            quote="We’re able to ship the same features as our competitor but with a quarter of the people they have, and Swarmia plays a part in this. With the investment data from Swarmia, we can see if the process changes we’ve implemented affect our investment balance."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <h3>Bridging engineering metrics with the business</h3>
          <p>
            Translating engineering effectiveness metrics to colleagues without
            a technical background can be tough. Before, Adam wrote longer,
            number-heavy recaps about his organization. Some members at Hostaway
            had a hard time interpreting the analysis, so Adam started using
            Swarmia to communicate the product roadmap.
          </p>
          <p>
            Now, screenshots from Swarmia help Adam communicate the product
            roadmap, developer productivity metrics, and possible bottlenecks to
            the leadership team faster.
          </p>
          <InlineQuote
            quote="Swarmia has changed how I communicate with the leadership team. Instead of showing them numbers, I can use the visualizations from Swarmia in a few slides with my comments."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <h2>
            Improved PR cycle time, better-balanced investments, and a
            well-communicated product roadmap
          </h2>
          <p>With the help of Swarmia, Hostaway has:</p>
          <ul>
            <li>
              Decreased their time to first review from days to less than 24
              hours
            </li>
            <li>
              Sustainably scaled their total throughput and PRs in progress
            </li>
            <li>Reduced their cycle times by 50%</li>
            <li>Increased their review rate by over 30%, and</li>
            <li>Reduced their technical debt from 24% to 5%</li>
          </ul>
          <p>all while almost tripling their engineering headcount.</p>
          <InlineQuote
            quote="Next, we’re hiring more engineering managers to ensure I’m not blocking our progress. We’ll definitely train them to use Swarmia when they start."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <p>
            Not long ago, Adam oversaw 30 developers directly. Now, Hostaway is
            expanding its team of engineering managers to bridge between Adam
            and the developers, ensuring Adam isn’t a bottleneck and that key
            engineering metrics keep trending in the right direction.
          </p>
          <InlineQuote
            quote="I can absolutely recommend Swarmia to other teams who are having the same issues we had."
            name="Adam Kupcewicz"
            title="Director of Engineering"
            photo={adam}
          />
          <p>
            For Adam and Hostaway, this is just the beginning. He’s positive
            Swarmia will play a key role in their growth in the upcoming years.
          </p>
        </>
      }
    />
  )
}
export default HostawayBody
