import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import HostawayBody from '../../components/story/hostaway/Content'
import HostawayHeader from '../../components/story/hostaway/Header'
import HostawayResults from '../../components/story/hostaway/Results'
import MetaImage from '../../assets/images/story/hostaway/hostaway-cover.png'

const HostawayPage = () => {
  return (
    <Layout
      title="Hostaway case study"
      description="The Director of Engineering at Hostaway shares how the engineering organization started shipping four times faster while doubling its engineering headcount."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <HostawayHeader />
        <HostawayResults />
        <HostawayBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best software teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default HostawayPage
